

import { Component } from "vue-property-decorator";
import { Closing } from "@/models/tasks.model";
import AddComment from "@/views/AddComment.vue";
import Axios from "axios";
import { BASE_API_URL,EventBus } from "../../../../config";
import DisplayFiles from "@/views/DisplayDocument.vue";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";
import CommonMixin from "@/mixins/common.mixin";
// import { EventBus } from "@/views/CommunicationPopup.vue";
import PipeLineSocketMixin from "@/mixins/PipeLineSocketMixin"
import { mixins } from "vue-class-component";

@Component({ components: { AddComment, DisplayFiles } })
export default class ClosingComponent extends mixins(PipeLineSocketMixin, CommonMixin) {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  public closingData = new Closing();
  public loanTxnId= null;
  public pipeLineHeaderData: any = {};
  public categories: any = {
    copyOfNote: 'Copy of Final Note',
    finalClosingDisclosure: 'Final Executed Closing Disclosures',
    copyOfMortgage: 'Copy of Final Mortgage',
  };
  public taskNameForRoom = 'closing';


private playWeLearnVideo() {
    EventBus.$emit("openWeLearn", { taskName: "Closing", videoUrl: null });
  }
  /**
   * API for fetching data of task and pipeline header.
   */
  public async fetchClosingTaskDetail() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/fetchClosingTaskDetail",
        { loanTxnId: this.loanTxnId });

      this.pipeLineHeaderData = response.data.pipeLineHeaderData;
      this.closingData = response.data.closingData;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }

  /**
   * API for saving data of task.
   */
  public async submit(ifSave) {
    try {


      ifSave = ifSave == "Save" ? true : false;
      let validComment = await this.$refs.comment['validateComment'](ifSave);


      if ((ifSave ? false : !await this.$validator.validateAll() ) || !validComment)
        return;
      /**
       * Taking Formdata for sending files and data
       */
      this.$store.state.wemloLoader = true;
      let formData = new FormData();

      // Appending loanTxnId here
      formData.append("loanTxnId", this.loanTxnId);
      /**
       * Appending comment data and comment attachments here in formdata
       */
      let commentObj: any = {
        comment: this.closingData.commentData.comment,
        showCommentToBroker: this.closingData.commentData.showCommentToBroker
      }
      if (this.closingData.commentData.commentAttachment.length > 0 && !this.closingData.commentData.commentAttachment[0].hasOwnProperty("path"))
        this.closingData.commentData.commentAttachment.forEach(file => {
          formData.append("commentAttachment", file);
        });
      else if (this.closingData.commentData.commentAttachment.length > 0)
        commentObj.commentAttachment = this.closingData.commentData.commentAttachment;
      formData.append("commentData", JSON.stringify(commentObj));

      if (
        this.closingData.finalClosingDisclosure &&
        !this.closingData.finalClosingDisclosure.hasOwnProperty("path")
      )
        this.closingData.finalClosingDisclosure.forEach(file => {
          formData.append("finalClosingDisclosure", file);
        });
      if (
        this.closingData.copyOfNote &&
        !this.closingData.copyOfNote.hasOwnProperty("path")
      )
        this.closingData.copyOfNote.forEach(file => {
          formData.append("copyOfNote", file);
        });
      if (
        this.closingData.copyOfMortgage &&
        !this.closingData.copyOfMortgage.hasOwnProperty("path")
      )
        this.closingData.copyOfMortgage.forEach(file => {
          formData.append("copyOfMortgage", file);
        });

      /**
       * Merging the save or complete option here with wemlo invoice and options
       */
      formData.append("ifSave", ifSave);
      formData.append("options", JSON.stringify(this.closingData.options));
      if (this.closingData.closingDisclosure && !this.closingData.closingDisclosure.hasOwnProperty('path'))
        formData.append( "closingDisclosure", this.closingData.closingDisclosure );

      /**
       * Finally sending request to server with formdata in body
       */

      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/closing",
        formData);
      this.$snotify[response.data.status == 200 ? "success" : response.data.status == 403 ? "info": "error"](response.data.message);
      EventBus.$emit('closeWeLearn',false); // Closing learn more video modal;     
      
      if (response.data.status == 200) {
        this.saveEvent();
      }

      if (!ifSave) {
        this.$router.push({ path: "dashboard" });
      }
    } catch (error) {
      console.log(error);
      const infectedFiles = this.checkResponseIfFileInfected(error);
      infectedFiles.forEach(filename => {
        this.removeInfectedFile(filename)
      })
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }
  public removeInfectedFile(filename: string): void {
    // check if comment attachment contains infected filename
    if (
      this.closingData.commentData.commentAttachment.length > 0 &&
      !this.closingData.commentData.commentAttachment[0].hasOwnProperty("path")
    )
    {
      this.closingData.commentData.commentAttachment = this.closingData.commentData.commentAttachment.filter(file => (file.name !== filename));
    }
    // check if finalClosingDisclosure contains infected filename
    if (
      this.closingData.finalClosingDisclosure &&
      !this.closingData.finalClosingDisclosure.hasOwnProperty("path")
    ) {
      this.closingData.finalClosingDisclosure = this.closingData.finalClosingDisclosure.filter(file => (file.name !== filename));
    }
    // check if copyOfNote contains infected filename
    if (
      this.closingData.copyOfNote &&
      !this.closingData.copyOfNote.hasOwnProperty("path")
    ) {
      this.closingData.copyOfNote = this.closingData.copyOfNote.filter(file => (file.name !== filename));
    }
    // check if copyOfMortgage contains infected filename
    if (
      this.closingData.copyOfMortgage &&
      !this.closingData.copyOfMortgage.hasOwnProperty("path")
    ) {
      this.closingData.copyOfMortgage = this.closingData.copyOfMortgage.filter(file => (file.name !== filename));
    }
  }

  async uploadFile(event, uploadedFileName) {
    if (event.target.files.length > 0) {
      Object.values(event.target.files).forEach(element => {
        if (uploadedFileName == "finalClosingDisclosure") {
          this.closingData.finalClosingDisclosure.push(element);
        } else if (uploadedFileName == "copyOfNote") {
          this.closingData.copyOfNote.push(element);
        } else if (uploadedFileName == "copyOfMortgage") {
          this.closingData.copyOfMortgage.push(element);
        }
      });
    }
  }

  public displayUploadFiles(file, viewFileName) {
    this.$refs.displayFiles["setInitialValuesForShowingSamplesWithDeleteButton"](file, true, viewFileName);
  }

  public deleteDoc(obj){
  this.closingData[obj.deleteData].splice(obj.index,1)
  }
  public async deleteFile(obj) {
    let index = this.closingData[obj.deleteData].findIndex(
      doc => doc.path == obj.files
    );
  this.closingData[obj.deleteData].splice(index,1)
    await this.deleteDisplayFromTaskCollection(index, obj.deleteData);
  }
  public async deleteDisplayFromTaskCollection(index, fileName) {
    const categoryName = this.categories[fileName];
    try {
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/deleteDocumentByCategoryName",
        { loanTxnId: this.loanTxnId, index: index, categoryName: categoryName });
    } catch (error) {
      console.log(error);
    }
  }

  public viewClosingDisclosure(allFiles) {
    this.$refs.comment["displayCommentAttachment"](allFiles , true);
  }

  public async deleteInvoice(check){
    this.$set(this.closingData, 'closingDisclosure' , 0);
    if(check=='S3')
      await this.deleteInvoiceFromTaskCollection();
  }


  async deleteInvoiceFromTaskCollection(){
    try {
      let response = await Axios.post(BASE_API_URL+ 'wemloprocessor/removeDataFromTaskCollection', 
        { loanTxnId:this.loanTxnId , path: 'tasks.closing.closingDisclosure' });
    } catch (error) {
      console.log(error);
    }
  }

  public uploadClosingDisclosure(event) {
    try {
      if (event.target.files.length > 0)
        this.closingData.closingDisclosure = event.target.files[0]
    } catch (error) {
      console.log(error);
    }
  }
  async mounted() {
    this.loanTxnId = this.$route.query.id;
    await this.fetchClosingTaskDetail();
  }


}
